const makeSelectValues = (items, options = {
    key: 'item',
    value: 'value',
    label: 'value',
}) => {
    const res = []
    const isObj = !Array.isArray(items)
    const iteratee = isObj ? Object.keys(items) : items
    for (let i = 0, il = iteratee.length; i < il; ++i) {
        const key = isObj ? iteratee[i] : i
        const item = items[key]
        res.push({
            key: options.key + i,
            value: (options.value === 'value') ? item : item[options.value],
            label: (options.value === 'value') ? item : item[options.label],
        })
    }
    return res
}

/**
 * Filters and sorts site data based on groupId and sort order.
 * @param {Object.<string, SiteObject>} sitesData - An object containing site data.
 * @param {string} currentSite - The key of the current site.
 * @returns {FilteredSite[]} An array of filtered and sorted site objects.
 */
const filterAndSortSites = (sitesData, currentSite) => {
    const result = []
    const groupIdMap = new Map()

    // Convert the object to an array of entries and sort by the 'sort' property
    const sortedEntries = Object.entries(sitesData)
        .sort((a, b) => a[1].sort - b[1].sort)

    for (const [key, site] of sortedEntries) {
        const { groupId, lang, name } = site

        if (!groupIdMap.has(groupId) || key === currentSite) {
            groupIdMap.set(groupId, key)

            result.push({
                key,
                value: lang,
                label: name,
            })
        }
    }

    return result
}

export { makeSelectValues, filterAndSortSites }
