<script setup>
import Globe from '~/assets/icons/globe.svg'
import XBold from '~/assets/icons/X-bold.svg'
import ArrowDown from '~/assets/icons/arrow-down.svg'
import ArrowLine from '~/assets/icons/arrow-line.svg'

const {
    navOpened,
    subnavOpened,
} = useClientState()

const {
    navs, sites, alternateUrls, activeSite, countryBannerVisible, globals,
    countryBannerOpened,
} = useSharedState()

const mobileSubmenuOpened = ref(null)
const itemsLength = ref(0)
itemsLength.value = (navs?.value?.main?.length ?? 0) + (navs?.value?.secondary?.length ?? 0)

const makeTransitionListEnterCumul = (el, done) => {
    transitionListEnterCumul(el, itemsLength.value, done)
}
</script>

<template>
    <Transition
        v-if="navs?.main?.length"
        enter-active-class="duration-650"
        enter-from-class="translate-y-[110%]"
        enter-to-class="translate-y-0"
        leave-active-class="duration-650"
        leave-from-class="translate-y-0"
        leave-to-class="translate-y-[110%]"
        v-on:enter="subnavOpened = true"
        v-on:leave="subnavOpened = false"
    >
        <nav
            v-show="navOpened"
            class="nav-overlay backface-hidden fixed right-0 top-0 z-62 -mt-12 min-h-screen w-full overflow-hidden rounded-t-5xl bg-darkblue pt-12 transition-all duration-650 xl:hidden"
            :class="{ 'top-32 xl:top-24': countryBannerVisible }"
        >
            <!-- @todo: remove clientonly and solve loading order on SSR -->
            <ClientOnly>
                <div class="px-5 py-3 text-right text-lg font-medium uppercase text-verylightblue">
                    <ul class="flex list-none items-center justify-end gap-4">
                        <li
                            v-for="item in alternateUrls"
                            :key="item.id"
                        >
                            <MainLink :to="item.fixed">
                                {{ sites[item.siteHandle].shortlang }}
                            </MainLink>
                        </li>
                        <li
                            v-for="link in globals.socialMedias.links"
                            :key="link.id"
                        >
                            <a
                                :href="link.url"
                                class="inline-block fill-current [&>svg]:h-[1em] [&>svg]:w-auto"
                                target="_blank"
                                v-html="link.iconPicker.inline"
                            />
                        </li>
                        <li class="inline-flex items-center">
                            <button
                                class="inline-flex items-center"
                                v-on:click="countryBannerOpened = true"
                            >
                                <Globe class="h-[1em] w-auto fill-current" />
                            </button>
                        </li>
                    </ul>
                </div>
            </ClientOnly>
            <Transition
                enter-active-class="duration-650 delay-100"
                enter-from-class="translate-y-full"
                enter-to-class="translate-y-0"
                leave-active-class="duration-650"
                leave-from-class="translate-y-0"
                leave-to-class="translate-y-full"
            >
                <div
                    v-show="subnavOpened"
                    class="rounded-t-5xl bg-lightblue pb-120 text-white"
                >
                    <div class="flex items-center justify-between pb-6 pl-7 pr-6 pt-11">
                        <MainLink
                            :to="`/${activeSite.prefix}`"
                            class="block"
                        >
                            <LogoEn class="w-[11.66666667rem] max-w-full fill-current" />
                        </MainLink>
                        <button
                            class="ml-4"
                            v-on:click.prevent="navOpened = !navOpened"
                        >
                            <XBold class="w-6 fill-current xs:w-auto" />
                        </button>
                    </div>
                    <div class="px-11 pb-16 uppercase">
                        <TransitionGroup
                            tag="ul"
                            :css="false"
                            class="mb-4 text-2xl uppercase"
                            v-on:enter="makeTransitionListEnterCumul"
                            v-on:leave="transitionListLeaveEmpty"
                        >
                            <li
                                v-for="(item, index) in navs.main"
                                v-show="subnavOpened"
                                :key="item.id"
                                class="mb-3"
                                :data-index="index"
                            >
                                <MainLink
                                    :to="item.url"
                                    :class="{ 'text-gold': mobileSubmenuOpened === item.id }"
                                    v-on:click.prevent="mobileSubmenuOpened = mobileSubmenuOpened === item.id ? null : item.id"
                                >
                                    {{ item.title }}

                                    <ArrowDown
                                        class="ml-1.5 inline-block h-auto w-3 fill-current transition duration-650"
                                        :class="{ '-rotate-180': mobileSubmenuOpened === item.id }"
                                    />
                                </MainLink>
                                <ul
                                    v-if="item.children"
                                    v-show="mobileSubmenuOpened === item.id"
                                >
                                    <li
                                        v-for="(child, indexChild) in item.children"
                                        :key="indexChild"
                                    >
                                        <MainLink
                                            :to="child.url"
                                        >
                                            {{ child.title }}
                                        </MainLink>
                                    </li>
                                </ul>
                            </li>
                        </TransitionGroup>
                        <TransitionGroup
                            tag="ul"
                            :css="false"
                            class="py-6"
                            v-on:enter="makeTransitionListEnterCumul"
                            v-on:leave="transitionListLeaveEmpty"
                        >
                            <li
                                v-for="(item, index) in navs.secondary"
                                v-show="subnavOpened"
                                :key="item.id"
                                :data-index="index + navs.main.length"
                                class="mb-1"
                            >
                                <MainLink :to="item.url">
                                    {{ item.title }}
                                </MainLink>
                            </li>
                        </TransitionGroup>
                    </div>
                </div>
            </Transition>

            <Transition
                enter-active-class="duration-650 delay-200"
                enter-from-class="translate-y-full"
                enter-to-class="translate-y-0"
                leave-active-class="duration-650"
                leave-from-class="translate-y-0"
                leave-to-class="translate-y-full"
            >
                <div
                    v-show="subnavOpened"
                    class="-mt-132 rounded-t-5xl bg-gold pb-120 pl-8 pr-4 pt-8 uppercase text-midblue"
                >
                    <div
                        v-for="(item, index) in navs.mobileSecondary"
                        :key="item.id"
                        :data-index="index"
                    >
                        <Transition
                            :css="false"
                            v-on:enter="makeTransitionListEnterCumul"
                            v-on:leave="transitionListLeaveEmpty"
                        >
                            <div
                                v-show="subnavOpened"
                                :key="'subsubnav'+1"
                                :data-index="navs.main.length + navs.secondary.length + 2"
                                class="text-lg"
                            >
                                {{ item.title }}
                            </div>
                        </Transition>
                        <TransitionGroup
                            v-if="item.children"
                            tag="ul"
                            :css="false"
                            class="grid grid-cols-auto-sm gap-x-3 gap-y-1 py-6 text-sm"
                            v-on:enter="makeTransitionListEnterCumul"
                            v-on:leave="transitionListLeaveEmpty"
                        >
                            <li
                                v-for="(child, indexChild) in item.children"
                                v-show="subnavOpened"
                                :key="'subsubnav'+1+indexChild"
                                :data-index="navs.main.length + navs.secondary.length + 2"
                                class="whitespace-nowrap"
                            >
                                <MainLink
                                    :to="child.url"
                                >
                                    <span class="mr-2 inline-block rounded-full border-2 border-gold bg-white text-center">
                                        <ArrowLine class="inline-block max-w-[40%] fill-gold" />
                                    </span>
                                    {{ child.title }}
                                </MainLink>
                            </li>
                        </TransitionGroup>
                    </div>
                </div>
            </Transition>
        </nav>
    </Transition>
</template>
