<script setup>
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'

const month = ref('')
const day = ref('')
const year = ref('')
const error = ref('')

const monthElement = ref(null)
const dayElement = ref(null)
const yearElement = ref(null)
const submitElement = ref(null)

const {
    scrolledMedium,
} = useClientState()

const {
    sites,
    activeSite,
    switchActiveSite,
    olnuf,
    setOlnuf,
    globalsSite,
    translationsSite,
} = useSharedState()

const selectedSite = ref(toRaw(activeSite.value.lang))
const currentGlobals = ref(globalsSite.value[selectedSite.value])
const currentTranslations = ref(translationsSite.value[selectedSite.value])
watch(selectedSite, (val) => {
    currentGlobals.value = globalsSite.value[val]
    currentTranslations.value = translationsSite.value[val]
})

const selectValues = filterAndSortSites(sites.value, activeSite.value)

const switchSite = async () => {
    const selectedDate = new Date(parseInt(year.value, 10), parseInt(month.value, 10) - 1, parseInt(day.value, 10))
    if (year.value !== '' && month.value !== '' && day.value !== '' && selectedDate instanceof Date && !Number.isNaN(selectedDate)) {
        /* Get the age of that date */
        const age = Math.floor((new Date() - selectedDate) / 1000 / 60 / 60 / 24 / 365)

        const comparedAge = currentGlobals?.value?.ageGate?.age ? parseInt(currentGlobals.value.ageGate.age, 10) : 18
        if (age >= comparedAge) {
            error.value = ''
            setOlnuf(true)
            await switchActiveSite(selectedSite.value)
        }
        else {
            error.value = currentGlobals?.value?.ageGate?.ageErrorMessage.replace('{age}', comparedAge)
        }
    }
    else {
        error.value = currentGlobals?.value?.ageGate?.invalidDateErrorMessage
    }
}

const redirectToSafety = () => {
    navigateTo('https://www.google.com', { external: true })
}

const changeFocusWhenFull = (event, nextElement, maxLength) => {
    if (event.target.value.length >= maxLength) {
        nextElement.focus()
    }
}
</script>

<template>
    <Transition
        leave-active-class="transition duration-650"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <UseFocusTrap
            v-if="currentGlobals?.ageGate?.image?.[0]?.url && olnuf === false"
            :options="{ immediate: true }"
        >
            <form
                v-show="currentGlobals?.ageGate?.image?.[0]?.url && olnuf === false"
                :options="{ immediate: true }"
                data-lenis-prevent
                class="size-screen fixed left-0 top-0 z-70 items-center justify-center overflow-auto bg-darkblue text-white"
                v-on:submit.prevent="switchSite"
            >
                <img
                    class="fixed z-0 size-full object-cover object-top"
                    :src="currentGlobals?.ageGate?.image?.[0]?.url"
                    :srcset="currentGlobals?.ageGate?.image?.[0]?.srcset"
                    :alt="currentGlobals?.ageGate?.image?.[0]?.alt"
                    :width="currentGlobals?.ageGate?.image?.[0]?.width"
                    :height="currentGlobals?.ageGate?.image?.[0]?.height"
                >
                <div class="relative z-10">
                    <div class="relative z-10">
                        <Transition
                            enter-active-class="transition delay-500"
                            enter-from-class="opacity-0 translate-y-2"
                            enter-to-class="opacity-100 translate-y-0"
                            leave-active-class="absolute transition"
                            leave-from-class="top-0 left-0 opacity-100"
                            leave-to-class="top-0 left-0 -translate-y-2 opacity-0"
                        >
                            <LogoEn
                                v-show="activeSite.shortlang === 'en'"
                                class="mx-4 mt-8 h-auto w-full max-w-[calc(100vw-2rem)] fill-current md:mx-8 md:w-78 lg:mt-16"
                                :wordmark-classes="{ 'opacity-0': scrolledMedium, 'opacity-100': !scrolledMedium }"
                            />
                        </Transition>

                        <Transition
                            enter-active-class="transition delay-500"
                            enter-from-class="opacity-0 translate-y-2"
                            enter-to-class="opacity-100 translate-y-0"
                            leave-active-class="absolute transition"
                            leave-from-class="top-0 left-0 opacity-100"
                            leave-to-class="top-0 left-0 -translate-y-2 opacity-0"
                        >
                            <LogoFr
                                v-show="activeSite.shortlang === 'fr'"
                                class="mx-4 mt-8 h-auto w-full max-w-[calc(100vw-2rem)] fill-current md:mx-8 md:w-78 lg:mt-16"
                                :wordmark-classes="{ 'opacity-0': scrolledMedium, 'opacity-100': !scrolledMedium }"
                            />
                        </Transition>
                    </div>
                    <div
                        class="mt-8 w-full max-w-xl rounded-2xl bg-midblue/60 px-4 py-12 text-center text-base xs:mx-4 xs:w-[calc(100vw-2rem)] md:mx-auto md:px-8"
                    >
                        <div
                            v-if="currentGlobals?.ageGate?.toptitle"
                            class="mb-3 text-xxs uppercase xs:text-xs"
                        >
                            {{ currentGlobals?.ageGate.toptitle }}
                        </div>
                        <div class="mb-4">
                            <div
                                v-if="selectValues.length"
                                class="relative m-auto w-full lg:w-92"
                            >
                                <CustomSelect
                                    v-model="selectedSite"
                                    :initial="activeSite.lang"
                                    select-tabindex="1"
                                    select-classes="!bg-faderblue text-xxs xs:text-sm md:text-base !border-faderblue"
                                    :choices="selectValues"
                                />
                            </div>
                        </div>
                        <div
                            v-if="currentGlobals?.ageGate?.maintitle"
                            class="mb-1 mt-4 text-xl leading-none xs:text-3xl"
                        >
                            {{ currentGlobals?.ageGate.maintitle }}
                        </div>
                        <div
                            v-if="currentGlobals?.ageGate?.subtitle"
                            class="mb-4 text-xxs uppercase xs:text-xs"
                        >
                            {{ currentGlobals?.ageGate.subtitle }}
                        </div>
                        <div class="flex gap-3 lg:grid-cols-3">
                            <input
                                ref="monthElement"
                                v-model="month"
                                tabindex="1"
                                class="mb-4 w-full appearance-none rounded-none border-b-2 border-gold bg-transparent py-2 text-xxs placeholder:text-white focus-visible:border-white focus-visible:outline-none xs:text-xs md:text-base [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                                type="number"
                                min="1"
                                max="12"
                                pattern="[0-9]{1,2}"
                                maxlength="2"
                                :placeholder="currentTranslations?.monthPlaceholder ?? 'Month (MM)'"
                                required
                                v-on:input="changeFocusWhenFull($event, dayElement, 2)"
                            >
                            <input
                                ref="dayElement"
                                v-model="day"
                                tabindex="1"
                                class="mb-4 w-full appearance-none rounded-none border-b-2 border-gold bg-transparent py-2 text-xxs placeholder:text-white focus-visible:border-white focus-visible:outline-none xs:text-xs md:text-base [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                                type="number"
                                min="1"
                                max="31"
                                pattern="[0-9]{1,2}"
                                :placeholder="currentTranslations?.dayPlaceholder ?? 'Day (DD)'"
                                required
                                v-on:input="changeFocusWhenFull($event, yearElement, 2)"
                            >
                            <input
                                ref="yearElement"
                                v-model="year"
                                tabindex="1"
                                class="mb-4 w-full appearance-none rounded-none border-b-2 border-gold bg-transparent py-2 text-xxs placeholder:text-white focus-visible:border-white focus-visible:outline-none xs:text-xs md:text-base [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                                type="number"
                                min="1900"
                                pattern="[0-9]{4}"
                                :placeholder="currentTranslations?.yearPlaceholder ?? 'Year (YYYY)'"
                                required
                                v-on:input="changeFocusWhenFull($event, submitElement, 4)"
                            >
                        </div>

                        <div
                            v-if="error"
                            class="mt-2 text-center text-sm text-red-500"
                        >
                            {{ error }}
                        </div>
                        <div class="mt-4 flex justify-center gap-4">
                            <button
                                ref="submitElement"
                                tabindex="2"
                                class="order-2 mt-2.5 w-full rounded-4xl bg-gold px-4 py-2 text-center font-medium uppercase text-white focus:outline-none focus:ring-2 lg:ml-3 lg:mt-0 lg:w-56 lg:px-6 lg:py-3"
                                type="submit"
                            >
                                {{ currentGlobals?.ageGate?.enter ?? 'Enter' }}
                            </button>
                            <button
                                tabindex="3"
                                class="order-1 mt-2.5 w-full rounded-4xl bg-faderblue px-4 py-2 text-center font-medium uppercase text-white lg:mt-0 lg:w-56 lg:px-6 lg:py-3"
                                v-on:click.prevent="redirectToSafety"
                            >
                                {{ currentGlobals?.ageGate?.leave ?? 'Leave' }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </UseFocusTrap>
    </Transition>
</template>
