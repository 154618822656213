<script setup>
import Globe from '~/assets/icons/globe.svg'

const {
    footerVisible,
    scrolledMedium,
} = useClientState()

const {
    alternateUrls,
    sites,
    globals,
    countryBannerOpened,
} = useSharedState()

defineProps({
    data: {
        type: Object,
        required: true,
        default: () => ({ children: [] }),
    },
})
</script>

<template>
    <div
        class="nav-secondary relative hidden items-center justify-end bg-midblue px-11 text-xs font-medium uppercase text-verylightblue xl:block"
    >
        <ul class="flex h-10 items-center justify-end gap-x-4">
            <li
                v-for="item in data"
                :key="item.id"
            >
                <MainLink
                    active-class="text-gold"
                    :to="item.url"
                >
                    {{ item.title }}
                </MainLink>
            </li>

            <!-- @todo: remove clientonly and solve loading order on SSR -->
            <ClientOnly>
                <li
                    v-for="item in alternateUrls"
                    :key="`lang`+item.id"
                >
                    <MainLink
                        active-class="text-gold"
                        :to="item.fixed"
                    >
                        {{ sites[item.siteHandle].shortlang }}
                    </MainLink>
                </li>
            </ClientOnly>

            <li
                v-for="link in globals.socialMedias.links"
                :key="link.id"
                class="inline-flex items-center"
            >
                <a
                    :href="link.url"
                    class="inline-flex w-auto items-center fill-current svg:h-[1em]"
                    target="_blank"
                    v-html="link.iconPicker.inline"
                />
            </li>
            <li class="inline-flex items-center">
                <button
                    class="inline-flex items-center"
                    v-on:click="countryBannerOpened = true"
                >
                    <Globe class="h-[1em] w-auto fill-current" />
                </button>
            </li>
        </ul>

        <div
            aria-hidden
            class="nav-left-corner pointer-events-none absolute -bottom-30 left-0 z-10 h-30 w-8 rounded-tl-4xl shadow-inverted-radius-top-left-midblue transition-[box-shadow,border-radius] duration-650"
            :class="{
                'rounded-tl-none shadow-none': (footerVisible || scrolledMedium),
            }"
        />
        <div
            aria-hidden
            class="nav-right-corner pointer-events-none absolute -bottom-30 right-0 z-10 h-30 w-8 rounded-tr-4xl shadow-inverted-radius-top-right-midblue transition-[box-shadow,border-radius] duration-650"
            :class="{
                'rounded-tr-none shadow-none': (footerVisible || scrolledMedium),
            }"
        />
    </div>
</template>
