<script setup>
const { countryBannerVisible } = useSharedState()
const { isCrawler } = useDevice()
</script>

<template>
    <div class="layout layout-default">
        <PartialsCountrySelector v-if="!isCrawler" />
        <PartialsAgeGate v-if="!isCrawler" />
        <div class="font-sans antialiased">
            <PartialsNavMobile />
            <PartialsNavDesktop />
            <div
                class="page z-12 -mb-12 mt-[var(--geloso-offset-top)] min-h-screen transition duration-650"
                :class="{
                    'translate-y-32 pb-32 xl:translate-y-24 xl:pb-24': countryBannerVisible,
                }"
            >
                <slot />
            </div>
            <PartialsFooter />
        </div>
    </div>
</template>
