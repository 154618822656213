import { animate } from 'motion'

const staggeredDuration = (numItems, duration = 0.5, stagger = 0.1) => {
    const val = duration - ((stagger / numItems) * numItems)
    return val
}

const transitionListLeaveEmpty = (el, done) => {
    window.setTimeout(650, () => {
        done()
    })
}

const transitionListEnterCumul = (el, num, done) => {
    const anim = animate(el, {
        transform: ['translateY(2rem) translateZ(0)', 'translateY(0rem) translateZ(0)'],
        opacity: [0, 1],
    }, {
        duration: staggeredDuration(num, 0.65, 0.12),
        easing: [0.14, 0.47, 0.45, 0.94],
        fill: 'both',
        composite: 'replace',
        delay: el.dataset.index * (0.12 / num) + 0.25,
        allowWebkitAcceleration: true,
    })

    anim.finished.then(() => {
        done()
    })
}

const transitionListEnter = (el, done) => {
    const anim = animate(el, {
        transform: ['translateY(2rem) translateZ(0)', 'translateY(0rem) translateZ(0)'],
        opacity: [0, 1],
    }, {
        duration: 1,
        easing: [0.14, 0.47, 0.45, 0.94],
        fill: 'both',
        composite: 'replace',
        delay: (el.dataset.index * 0.05),
        allowWebkitAcceleration: true,
    })

    anim.finished.then(() => {
        done()
    })
}

const transitionListLeave = (el, num, done) => {
    const anim = animate(el, {
        transform: ['translateX(2rem)', 'translateX(0rem)'],
        opacity: [1, 0],
    }, {
        duration: 1,
        easing: [0.14, 0.47, 0.45, 0.94],
        fill: 'both',
        composite: 'replace',
        delay: el.dataset.index * (0.1 / num),
    })

    anim.finished.then(() => {
        done()
    })
}

export {
    transitionListEnter, transitionListLeaveEmpty, transitionListEnterCumul, staggeredDuration, transitionListLeave,
}
