<script setup>
import LogoDrinkDrive from '~/assets/icons/LogoDrinkDrive.svg'
import Globe from '~/assets/icons/globe.svg'

const {
    innerHeight,
    innerWidth,
} = useClientState()

const {
    navs,
    globals,
    sites,
    alternateUrls,
    activeSite,
    countryBannerOpened,
} = useSharedState()

const offset = computed(() => (innerHeight.value / innerWidth.value) * -1)
</script>

<template>
    <footer
        v-if="navs?.footerMain && globals?.footerContent?.textContent"
        class="relative z-11 overflow-hidden bg-midblue"
    >
        <Parallax
            id="footer"
            :enter="false"
            :leave="false"
            :animation="false"
            :progress-offset="offset"
        >
            <ParallaxChild
                :animation="{ transform: ['translateY(-24rem) translateZ(0)', 'translateY(0rem) translateZ(0)'] }"
                :enter="false"
                :leave="false"
                class="relative z-11 bg-midblue"
            >
                <div class="relative pb-36 pl-8 pr-7 pt-12 text-white xl:px-20 xl:pb-20 xl:pt-36">
                    <div class="items-end lg:flex lg:items-center">
                        <ul class="mb-12 mt-4 flex w-full items-center justify-end gap-x-4 lg:order-3 lg:my-0 lg:ml-2">
                            <ClientOnly>
                                <li
                                    v-for="item in alternateUrls"
                                    :key="`lang`+item.id"
                                >
                                    <MainLink
                                        class="font-medium uppercase text-verylightblue"
                                        :to="item.fixed"
                                    >
                                        {{ sites[item.siteHandle].shortlang }}
                                    </MainLink>
                                </li>
                            </ClientOnly>
                            <li
                                v-for="link in globals.socialMedias.links"
                                :key="link.id"
                                class="flex justify-start text-verylightblue"
                            >
                                <a
                                    :href="link.url"
                                    class="inline-block grow-0 fill-current"
                                    target="_blank"
                                    v-html="link.iconPicker.inline"
                                />
                            </li>
                            <li class="inline-flex items-center text-verylightblue">
                                <button
                                    class="inline-flex items-center"
                                    v-on:click="countryBannerOpened = true"
                                >
                                    <Globe class="h-[1em] w-auto fill-current" />
                                </button>
                            </li>
                        </ul>
                        <MainLink
                            :to="`/${activeSite.prefix}`"
                            class="shrink-0 grow-0 lg:order-1"
                        >
                            <Transition
                                enter-active-class="transition delay-500"
                                enter-from-class="opacity-0 translate-y-2"
                                enter-to-class="opacity-100 translate-y-0"
                                leave-active-class="absolute transition"
                                leave-from-class="top-0 left-0 opacity-100"
                                leave-to-class="top-0 left-0 -translate-y-2 opacity-0"
                            >
                                <LogoEn
                                    v-show="activeSite.shortlang === 'en'"
                                    class="max-w-full fill-white"
                                />
                            </Transition>

                            <Transition
                                enter-active-class="transition delay-500"
                                enter-from-class="opacity-0 translate-y-2"
                                enter-to-class="opacity-100 translate-y-0"
                                leave-active-class="absolute transition"
                                leave-from-class="top-0 left-0 opacity-100"
                                leave-to-class="top-0 left-0 -translate-y-2 opacity-0"
                            >
                                <LogoFr
                                    v-show="activeSite.shortlang === 'fr'"
                                    class="max-w-full fill-white"
                                />
                            </Transition>
                        </MainLink>
                    </div>
                    <div class="mt-9 lg:mt-15 lg:flex">
                        <div class="mb-13 lg:mb-0 lg:w-1/3">
                            <PartialsNavFooterMain :data="navs.footerMain" />
                        </div>
                        <div class="mb-9 lg:mb-0 lg:w-1/3 xl:w-1/4">
                            <PartialsNavFooterSecondary :data="navs.footerSecondary" />
                        </div>
                        <div
                            class="footerContent text-left text-verylightblue lg:w-1/3 lg:text-right xl:w-1/2"
                            v-html="globals.footerContent.textContent"
                        />
                    </div>
                </div>
            </ParallaxChild>
            <ParallaxChild
                :animation="{ transform: ['translateY(-32rem) translateZ(0)', 'translateY(0rem) translateZ(0)'] }"
                :enter="false"
                :leave="false"
                class="relative z-10 w-full bg-darkerblue px-8 py-9 text-sm text-faderblue lg:text-xs xl:px-30 xl:py-12"
                enter-leave-wrapper-classes="flex flex-col gap-4 lg:gap-8 lg:flex-row w-full lg:items-center"
            >
                <ul class="flex items-center gap-x-4">
                    <li
                        v-for="item in navs.footerCredits"
                        :key="item.id"
                    >
                        <MainLink
                            active-class="text-gold"
                            :to="item.url"
                            class="flex cursor-pointer items-center transition hover:text-gold"
                        >
                            {{ item.title }}
                        </MainLink>
                    </li>
                </ul>
                <div
                    class="mr-[10%] max-w-[60%] lg:mr-0 lg:max-w-full"
                    v-html="globals.footerCopyright.textContent"
                />
                <div class="lg:ml-auto">
                    <LogoDrinkDrive class="max-w-full fill-white" />
                </div>
            </ParallaxChild>
        </Parallax>
    </footer>
</template>
